








































































import userService from '@/shared/services/userService'
import { mapGetters } from 'vuex'
import Vue from 'vue'
import { IVehicle } from '@/shared/model/vehicle.model'
import { debounceInput } from '@/shared/helpers'

export default Vue.extend({
  name: 'ForumTable',

  data() {
    return {
      topics: [] as any[],
      vehicles: [] as IVehicle[],
      selectedVehicle: undefined as undefined | IVehicle,
      debounce: undefined as number | undefined,
      total: 0,
      options: {} as any,
      search: '',

      headers: [
        {
          text: this.$t('title'),
          value: 'title',
        },
        {
          text: this.$t('created'),
          value: 'createdAt',
        },
        {
          text: this.$t('last-answer'),
          value: 'updatedAt',
        },
        {
          text: this.$t('vehicle'),
          value: 'vehicle.name',
        },
        {
          sortable: false,
          text: this.$t('answers'),
          value: 'answers',
        },
        {
          sortable: false,
          text: this.$t('actions'),
          value: 'actions',
          align: 'end',
        },
      ],
    }
  },

  computed: {
    ...mapGetters({
      account: 'account',
      company: 'company',
    }),
    userIsSystemAdmin() {
      return userService.userIsSystemAdmin()
    },
  },
  async mounted() {
    this.getTopics()
    this.getVehicles()
  },
  watch: {
    selectedVehicle: {
      async handler() {
        this.getTopics()
        this.options.page = 1
      },
    },
    search: {
      async handler() {
        this.debounce = debounceInput(this.debounce, this.getTopics)
        this.options.page = 1
      },
    },
    options: {
      handler() {
        this.getTopics()
      },
    },
  },
  methods: {
    userCanDeleteTopic(userIdThatCreatedTopic: string) {
      return this.userIsSystemAdmin || userIdThatCreatedTopic === this.account._id
    },
    async getVehicles() {
      const result = await this.axios.get('/api/vehicle')
      if (result.status === 200 && result.data) {
        this.vehicles = result.data.items
      }
    },
    async getTopics() {
      const query = {
        l: this.options.itemsPerPage,
        lo: this.options.page - 1,
        sf: this.options.sortBy[0] ? this.options.sortBy[0] : 'createdAt',
        so: this.options.sortDesc[0] ? 'asc' : 'desc',
        vehicle: this.selectedVehicle ? this.selectedVehicle : undefined,
        search: this.search,
      }
      const result = await this.axios.get('/api/forumTopics', { params: query })
      if (result.status === 200 && result.data) {
        this.topics = result.data.items
        this.total = result.data.total
      }
    },
    async deleteTopic(id: string) {
      this.$confirm(`${this.$t('dialog.are-you-sure')}`, {
        color: 'error',
        buttonTrueText: this.$t('yes').toString(),
        buttonFalseText: this.$t('no').toString(),
        title: this.$t('dialog.delete-topic').toString(),
      }).then(async (res) => {
        if (res) {
          await this.axios.delete('/api/forumTopic/' + id)
          this.getTopics()
        }
      })
    },
    getVehicleName(vehicleId: string) {
      const vehicle = this.vehicles.find((x) => x._id === vehicleId)
      return vehicle ? vehicle.name : this.$t('name-not-found')
    },
  },
})
