var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"id":"dashboard","fluid":"","tag":"section"}},[_c('v-breadcrumbs'),_c('v-row',[_c('v-col',[_c('base-material-card',{staticClass:"px-5 py-3",attrs:{"color":"success","icon":"mdi-forum","inline":"","title":_vm.$t('topics')}},[_c('v-row',{staticStyle:{"justify-content":"right"}},[_c('v-autocomplete',{staticClass:"ml-4",staticStyle:{"max-width":"240px"},attrs:{"label":_vm.$t('vehicle'),"items":_vm.vehicles,"item-text":"name","item-value":"_id"},model:{value:(_vm.selectedVehicle),callback:function ($$v) {_vm.selectedVehicle=$$v},expression:"selectedVehicle"}}),_c('v-text-field',{staticClass:"responsive-table-search-input mr-4",attrs:{"append-icon":"mdi-magnify","label":_vm.$t('search'),"hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-row',[_c('v-col',{staticStyle:{"text-align":"right"}},[_c('v-data-table',{attrs:{"options":_vm.options,"items":_vm.topics,"server-items-length":_vm.total,"headers":_vm.headers},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([{key:"item.createdAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateForTopics")(item.createdAt))+" ")]}},{key:"item.updatedAt",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDateForTopics")(item.updatedAt))+" ")]}},{key:"item.vehicleId",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.getVehicleName(item.vehicleId))+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"small":"","to":'/app/forum/' + item._id}},on),[_c('v-icon',[_vm._v("mdi-eye")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.open-topic')))])]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(_vm.userCanDeleteTopic(item.user))?_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"color":"error","small":""},on:{"click":function($event){return _vm.deleteTopic(item._id)}}},on),[_c('v-icon',[_vm._v("mdi-delete")])],1):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tooltip.delete-topic')))])])]}}])}),_c('v-btn',{attrs:{"color":"success","to":"/app/forum/new"}},[_vm._v(_vm._s(_vm.$t('create-new-topic')))])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }